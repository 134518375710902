import React from 'react';
import './Header.css';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import useAuth from '../auth/auth';
import { Avatar } from 'primereact/avatar';

function Header() {
  const authContext = useAuth();
  const navigate = useNavigate();

  const items = [
    {
      label: 'Home',
      icon: 'pi pi-fw pi-home',
      command: () => navigate('/')
    },
    {
      label: 'Sign out',
      icon: 'pi pi-fw pi-sign-out',
      command: () => navigate('/logout')
    }
  ];

  // const end = <InputText placeholder="Search" type="text" />;
  const end = (
    <span>
      <Avatar
        label={authContext.user?.user.displayName?.substring(0, 1)}
        className="mr-2"
        size="large"
        style={{ backgroundColor: '#2196F3', color: '#ffffff' }}
        shape="circle"
      />
    </span>
  );

  return (
    <div className="Header">
      <Menubar model={items} end={end} />
    </div>
  );
}

export default Header;
