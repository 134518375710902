import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { auth } from '../firebase';
import { useEffect } from 'react';
import useAuth from './auth';
import { useLocation, useNavigate } from 'react-router-dom';
import './Login.css';
import { Button } from 'primereact/button';

function Login() {
  const authContext = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(auth);

  useEffect(() => {
    if (authContext.user) {
      navigate(from, { replace: true });
    }
  });

  useEffect(() => {
    if (!authContext.user && user) {
      authContext.setUser(user);
      navigate(from, { replace: true });
    }
  }, [user]);

  const content = (
    <div>
      <div className="flex justify-content-center pb-4">
        <img style={{ width: '12em' }} src="./images/gs-logo.png" />
      </div>
      <Button
        key={'signInWithGoogle'}
        label={'Sign In with Google'}
        className="flex align-items-center justify-content-center m-2 p-button-raised"
        loading={loading}
        disabled={loading}
        onClick={() => signInWithGoogle()}
      />
    </div>
  );

  let errorContent = <div className="error"></div>;
  if (error) {
    errorContent = <div className="error">Unable to sign in</div>;
  }

  return (
    <div className="Login">
      {content}
      {errorContent}
    </div>
  );
}

export default Login;
