import './Admin.css';
import { useEffect, useState } from 'react';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import { getAllCookieLinkLaunchDetails } from '../repositories/cookie-link-launch-detail-repository';
import { db } from '../firebase';
import { getScouts } from '../repositories/scout-repository';
import { Scout } from '../models/scout';
import Header from '../header/Header';
import classNames from 'classnames';
import { LinkLaunchDetailsTable } from './LinkLaunchDetailsTable';
import { LinkLaunchDetailsMap } from './LinkLaunchDetailsMap';
import { LoadingMessage } from '../common/LoadingMessage';
import { ErrorMessage } from '../common/ErrorMessage';
import { ScoutSummary } from './ScoutSummary';
import { Panel } from 'primereact/panel';
import { LinkLaunchDetailsHistoryChart } from './LinkLaunchDetailsHistoryChart';

function Admin() {
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);
  const [detailsError, setDetailsError] = useState<boolean>(false);
  const [details, setDetails] = useState<CookieLinkLaunchDetail[] | undefined>(undefined);
  const [scouts, setScouts] = useState<Scout[]>([]);
  const [loadingScouts, setLoadingScouts] = useState<boolean>(false);
  const [scoutsError, setScoutsError] = useState<boolean>(false);

  useEffect(() => {
    setDetailsError(false);
    setLoadingDetails(true);
    getAllCookieLinkLaunchDetails(db)
      .then((data) => {
        setDetails(data);
        setLoadingDetails(false);
      })
      .catch((error) => {
        console.error('Unable to load all link launch details', error);
        setDetailsError(true);
        setLoadingDetails(false);
      });
  }, []);

  useEffect(() => {
    setScoutsError(false);
    setLoadingScouts(true);
    getScouts(db)
      .then((data) => {
        data ? setScouts(data) : setScouts([]);
        setLoadingScouts(false);
      })
      .catch(() => {
        setScouts([]);
        setLoadingScouts(false);
        setScoutsError(true);
      });
  }, []);

  let scoutSummaryContent;
  if (scoutsError || detailsError) {
    scoutSummaryContent = (
      <div className="p-2">
        <ErrorMessage message={'Unable to load scout summary'} />
      </div>
    );
  } else if (loadingScouts || loadingDetails) {
    scoutSummaryContent = (
      <div className="p-2">
        <LoadingMessage message={'Loading scout summaries'} />
      </div>
    );
  } else {
    scoutSummaryContent = (
      <ScoutSummary scouts={scouts ? scouts : []} details={details ? details : []} />
    );
  }

  let linkClickDetailsContent;
  if (detailsError || scoutsError) {
    linkClickDetailsContent = (
      <div className="p-2">
        <ErrorMessage message={'Unable to load launch history'} />
      </div>
    );
  } else {
    if (loadingScouts || loadingDetails) {
      linkClickDetailsContent = (
        <div className="p-2">
          <LoadingMessage message={'Loading launch history'} />
        </div>
      );
    } else {
      linkClickDetailsContent = (
        <LinkLaunchDetailsTable details={details ? details : []} scouts={scouts ? scouts : []} />
      );
    }
  }

  let linkClickMapContent;
  if (detailsError || scoutsError) {
    linkClickMapContent = (
      <div className="p-2">
        <ErrorMessage message={'Unable to load launch map'} />
      </div>
    );
  } else if (loadingDetails || loadingScouts) {
    linkClickMapContent = (
      <div className="p-2">
        <LoadingMessage message={'Loading launch map'} />
      </div>
    );
  } else {
    linkClickMapContent = <LinkLaunchDetailsMap details={details ? details : []} />;
  }

  let linkClickHistoryChartContent;
  if (detailsError || scoutsError) {
    linkClickHistoryChartContent = (
      <div className="p-2">
        <ErrorMessage message={'Unable to load frequency chart'} />
      </div>
    );
  } else if (loadingDetails || loadingScouts) {
    linkClickHistoryChartContent = (
      <div className="p-2">
        <LoadingMessage message={'Loading launch frequency chart'} />
      </div>
    );
  } else {
    linkClickHistoryChartContent = (
      <LinkLaunchDetailsHistoryChart
        details={details ? details : []}
        scouts={scouts ? scouts : []}
      />
    );
  }

  return (
    <div className="Admin">
      <Header></Header>
      <div
        className={classNames({
          flex: true,
          'flex-wrap': true,
          'justify-content-center': loadingDetails || loadingScouts || scoutsError || detailsError,
          'pt-2': loadingDetails || loadingScouts || scoutsError || detailsError
        })}
      >
        {scoutSummaryContent}
      </div>

      <div className="pl-2 pt-2 pr-2 pb-1">
        <Panel header="Click Frequency" toggleable>
          {linkClickHistoryChartContent}
        </Panel>
      </div>

      <div className="pl-2 pt-2 pr-2 pb-1">
        <Panel header="Geographic Distribution" toggleable>
          {linkClickMapContent}
        </Panel>
      </div>

      <div className="pl-2 pr-2 pt-1 pb-2">
        <Panel header="Click History" toggleable>
          {linkClickDetailsContent}
        </Panel>
      </div>
    </div>
  );
}

export default Admin;
