export interface LoadingMessageProps {
  message: string | undefined;
}

export function LoadingMessage(props: LoadingMessageProps) {
  return (
    <div className="w-full text-center inline-flex align-items-center justify-content-center">
      <i className="pi pi-spin pi-spinner" style={{ fontSize: '1em', marginRight: '.5em' }}></i>
      <span>{props.message?.length ? props.message : 'Loading...'}</span>
    </div>
  );
}
