import React from 'react';
import './App.css';
import { Outlet } from 'react-router-dom';
// import { db } from './firebase';

function App() {
  return (
    <div className="App">
      {/*<Header />*/}
      <main style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        {/*<Outlet context={{ db: db }} />*/}
        <Outlet />
      </main>
      {/*<Footer />*/}
    </div>
  );
}

export default App;
