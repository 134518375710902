import { Scout } from '../models/scout';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact';
import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Formik, FormikValues } from 'formik';
import { saveScout } from '../repositories/scout-repository';
import { db } from '../firebase';
import { useState } from 'react';
import { ErrorMessage } from '../common/ErrorMessage';
import { ColorPicker } from 'primereact/colorpicker';
import { isUri } from 'valid-url';

export interface ScoutEditorProps {
  scout: Scout;
  onSave(scout: Scout): void;
}

const validateString = (value: string, required = true, nonEmpty = true): boolean => {
  let valid = true;
  if (required && !value) {
    valid = false;
  }
  if (nonEmpty && !value?.trim().length) {
    valid = false;
  }
  return valid;
};

const validateName = (name: string): boolean => {
  return validateString(name, true, true);
};

const validateUrl = (url: string): boolean => {
  if (!validateString(url, true, true)) {
    return false;
  }
  return isUri(url.trim())?.length ? true : false;
};

const validateColor = (color: string): boolean => {
  return validateString(color, true, true);
};

const validateValues = (values: FormikValues): ScoutEditorFormErrors | undefined => {
  let errors: ScoutEditorFormErrors | undefined = undefined;
  if (!validateName(values.name)) {
    if (!errors) {
      errors = {} as ScoutEditorFormErrors;
    }
    errors.name = 'Name is required';
  }
  if (!validateUrl(values.url)) {
    if (!errors) {
      errors = {} as ScoutEditorFormErrors;
    }
    errors.url = 'URL is required';
  }
  if (!validateColor(values.color)) {
    if (!errors) {
      errors = {} as ScoutEditorFormErrors;
    }
    errors.color = 'Color is required';
  }
  return errors;
};

interface ScoutEditorFormErrors {
  name: string | undefined;
  url: string | undefined;
  color: string | undefined;
}

export function ScoutEditor(props: ScoutEditorProps) {
  const scout = props.scout;
  const [saveError, setSaveError] = useState<boolean>(false);

  const saveErrorContent = saveError ? (
    <div className="pt-1">
      <ErrorMessage message={'Unable to save changes'} />
    </div>
  ) : (
    <span></span>
  );

  return (
    <Formik
      initialValues={{
        id: scout.id,
        enabled: scout.enabled,
        name: scout.name,
        url: scout.url,
        color: scout.color
      }}
      validate={(values) => validateValues(values)}
      onSubmit={(values, { setSubmitting }) => {
        setSaveError(false);
        saveScout(db, values as Scout)
          .then((success) => {
            if (success) {
              setSubmitting(false);
              props.onSave(values as Scout);
            } else {
              setSaveError(true);
              console.error('Unable to save scout');
              setSubmitting(false);
            }
          })
          .catch((error) => {
            setSaveError(true);
            console.error('Unable to save scout', error);
            setSubmitting(false);
          });
      }}
    >
      {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="p-fluid">
          <div className="field-checkbox">
            <Checkbox
              inputId="enabled"
              name="enabled"
              checked={values.enabled}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <label htmlFor="enabled">Enabled</label>
          </div>

          <div className="field">
            <label htmlFor="name" className="block">
              Name
            </label>
            <InputText
              id="name"
              aria-describedby="name-help"
              className={classNames({
                block: true,
                'p-invalid': !validateName(values.name)
              })}
              value={values.name}
              onChange={handleChange}
            />
          </div>

          <div className="field">
            <label htmlFor="url" className="block">
              URL
            </label>
            <span className="p-input-icon-right">
              <i
                className="pi pi-external-link cursor-pointer"
                onClick={() => {
                  if (validateUrl(values.url)) {
                    window.open(values.url, '_blank');
                  }
                }}
                title="Test URL"
              />
              <InputText
                id="url"
                aria-describedby="url-help"
                className={classNames({
                  block: true,
                  'p-invalid': !validateUrl(values.url)
                })}
                value={values.url}
                onChange={handleChange}
              />
            </span>
          </div>

          <div className="field">
            <label htmlFor="color" className="block">
              Color
            </label>
            <ColorPicker
              id="color"
              aria-describedby="color-help"
              className={classNames({
                block: true,
                'p-invalid': !validateColor(values.color)
              })}
              value={values.color}
              onChange={handleChange}
            />
          </div>

          <Button
            type="submit"
            label="Save"
            iconPos="right"
            loading={isSubmitting}
            disabled={isSubmitting || validateValues(values) !== undefined}
          />
          {saveErrorContent}
        </form>
      )}
    </Formik>
  );
}
