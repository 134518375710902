import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import './LinkLaunchDetailsMap.css';
import { GMap } from 'primereact';

export interface LinkLaunchDetailsMapProps {
  details: CookieLinkLaunchDetail[];
}

export function LinkLaunchDetailsMap(props: LinkLaunchDetailsMapProps) {
  const details = props.details;

  const latLngMap: Map<string, CookieLinkLaunchDetail[]> = new Map();
  let numLatLngs = 0;
  let avgLat = 0.0;
  let avgLng = 0.0;

  if (details) {
    let totalLat = 0.0;
    let totalLng = 0.0;

    details
      .filter((detail) => detail && detail.clientInfo)
      .forEach((detail) => {
        const lat = detail.clientInfo?.latitude;
        const lng = detail.clientInfo?.longitude;
        let latLng = '0.0|0.0';
        if (lat !== undefined && lng !== undefined) {
          latLng = lat + '|' + lng;
          totalLat += lat;
          totalLng += lng;
          numLatLngs++;
        }
        let latLngList = latLngMap.get(latLng);
        if (!latLngList) {
          latLngList = [detail];
        } else {
          latLngList.push(detail);
        }
        latLngMap.set(latLng, latLngList);
      });

    if (numLatLngs > 0) {
      avgLat = totalLat / numLatLngs;
      avgLng = totalLng / numLatLngs;
    }
  }

  const markers: google.maps.Marker[] = [];
  if (latLngMap && latLngMap.size) {
    latLngMap.forEach((detailList, latLng) => {
      const latLngPieces = latLng.split('|');
      const lat = +latLngPieces[0];
      const lng = +latLngPieces[1];
      const sampleDetail = detailList
        .filter(
          (detail) =>
            detail &&
            detail.clientInfo &&
            detail.clientInfo.region?.length &&
            detail.clientInfo.city?.length
        )
        .pop();
      const regionCity = sampleDetail
        ? sampleDetail.clientInfo?.city + ', ' + sampleDetail.clientInfo?.region
        : 'Unknown Location';
      const marker = new google.maps.Marker({
        position: { lat: lat, lng: lng },
        title: regionCity + ': ' + (detailList ? detailList.length : '?') + ' Link Launches',
        label: detailList ? '' + detailList.length : '?'
      });
      markers.push(marker);
    });
  }

  const options = {
    center: { lat: avgLat, lng: avgLng },
    zoom: 4
  };

  return (
    <div className="LinkLaunchDetailsMap">
      <GMap options={options} overlays={markers} style={{ width: '100%', minHeight: '320px' }} />
    </div>
  );
}
