import { Scout } from '../models/scout';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import { useState } from 'react';
import { Chart } from 'primereact';
import { buildScoutIdToColorMap, buildScoutIdToNameMap } from '../repositories/scout-repository';
import dayjs from 'dayjs';

export interface LinkLaunchDetailsHistoryChartProps {
  scouts: Scout[];
  details: CookieLinkLaunchDetail[];
}

const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const buildChartData = (scouts: Scout[], details: CookieLinkLaunchDetail[]): any => {
  const scoutIdToNameMap = buildScoutIdToNameMap(scouts);
  const scoutIdToColorMap = buildScoutIdToColorMap(scouts);

  // Build a compound mapping of scoutId -> date -> #clicks
  const scoutIdToDateDataMap = new Map<string, Map<string, number>>();
  details
    .filter((detail) => detail && detail.scoutId?.length && detail.clickedAt)
    .forEach((detail) => {
      if (!detail.scoutId?.length) {
        return;
      }
      if (!detail.clickedAt) {
        return;
      }
      let dateToDataMap = scoutIdToDateDataMap.get(detail.scoutId);
      if (!dateToDataMap) {
        dateToDataMap = new Map<string, number>();
      }
      const date = dayjs(detail.clickedAt.toDate()).format('MM/DD/YYYY');
      let data = dateToDataMap.get(date);
      if (data === undefined || data === null) {
        data = 0;
      }
      data++;
      dateToDataMap.set(date, data);
      scoutIdToDateDataMap.set(detail.scoutId, dateToDataMap);
    });

  // Find the first date that we have data to start the time window there
  const timestampsWithData = details
    .filter((detail) => detail && detail.clickedAt)
    .map((detail) => detail.clickedAt)
    .sort();
  let firstDate = timestampsWithData?.length ? timestampsWithData[0]?.toDate() : undefined;
  // If we didn't find any dates with data -- just use today
  if (!firstDate) {
    firstDate = new Date();
  }
  const todayDate = dayjs(new Date());

  // Build a list of all dates between the first date and today
  const allDates: string[] = [];
  let nextDate = dayjs(firstDate);
  while (nextDate.isBefore(todayDate)) {
    allDates.push(nextDate.format('MM/DD/YYYY'));
    nextDate = nextDate.add(1, 'day');
  }
  allDates.push(todayDate.format('MM/DD/YYYY'));

  // Build the datasets for each scout
  const datasets: any[] = [];
  scoutIdToDateDataMap.forEach((dateToDataMap, scoutId) => {
    const data: number[] = [];
    allDates.forEach((date) => {
      const dateData = dateToDataMap.get(date);
      if (dateData) {
        data.push(dateData);
      } else {
        data.push(0);
      }
    });
    datasets.push({
      type: 'bar',
      label: scoutIdToNameMap.get(scoutId),
      backgroundColor: scoutIdToColorMap.get(scoutId)
        ? `#${scoutIdToColorMap.get(scoutId)}`
        : getRandomColor(),
      data: data
    });
  });

  const data = {
    labels: [...allDates],
    datasets: [...datasets]
  };
  return data;
};

export function LinkLaunchDetailsHistoryChart(props: LinkLaunchDetailsHistoryChartProps) {
  const [scouts] = useState(props.scouts);
  const [details] = useState(props.details);

  const [data] = useState(buildChartData(scouts, details));

  const stackedOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        stacked: true,
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      }
    }
  };

  return (
    <div className="LinkLaunchDetailsHistoryChart">
      <Chart type="bar" data={data} options={stackedOptions} height="200" />
    </div>
  );
}
