import {
  addDoc,
  collection,
  DocumentData,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
  deleteDoc
} from 'firebase/firestore';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import { QueryDocumentSnapshot } from '@firebase/firestore';

export const publishCookieLinkLaunchDetail = async (
  db: Firestore,
  cookieLinkLaunchDetail: CookieLinkLaunchDetail
): Promise<string | undefined> => {
  const cookieLinkLaunchDetailsRef = collection(db, 'cookie-link-launch-details');
  const docRef = await addDoc(cookieLinkLaunchDetailsRef, cookieLinkLaunchDetail);
  return Promise.resolve(docRef?.id);
};

export const getCookieLinkLaunchDetailRefs = async (
  db: Firestore,
  startDoc?: QueryDocumentSnapshot<DocumentData> | undefined,
  pageSize = 10,
  scoutId?: string | undefined
): Promise<
  [QueryDocumentSnapshot<DocumentData>[], QueryDocumentSnapshot<DocumentData> | undefined]
> => {
  const cookieLinkLaunchDetailsRef = collection(db, 'cookie-link-launch-details');
  let q;
  if (startDoc) {
    if (scoutId?.length) {
      q = query(
        cookieLinkLaunchDetailsRef,
        where('scoutId', '==', scoutId),
        orderBy('clickedAt', 'desc'),
        startAfter(startDoc),
        limit(pageSize)
      );
    } else {
      q = query(
        cookieLinkLaunchDetailsRef,
        orderBy('clickedAt', 'desc'),
        startAfter(startDoc),
        limit(pageSize)
      );
    }
  } else {
    if (scoutId?.length) {
      q = query(
        cookieLinkLaunchDetailsRef,
        where('scoutId', '==', scoutId),
        orderBy('clickedAt', 'desc'),
        limit(pageSize)
      );
    } else {
      q = query(cookieLinkLaunchDetailsRef, orderBy('clickedAt', 'desc'), limit(pageSize));
    }
  }
  const querySnapshot = await getDocs(q);
  let lastVisible = undefined;
  try {
    lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  } catch (error) {
    lastVisible = undefined;
  }
  return Promise.resolve(querySnapshot.docs ? [querySnapshot.docs, lastVisible] : [[], undefined]);
};

export const getCookieLinkLaunchDetails = async (
  db: Firestore,
  startDoc?: QueryDocumentSnapshot<DocumentData> | undefined,
  pageSize = 10,
  scoutId?: string | undefined
): Promise<[CookieLinkLaunchDetail[], QueryDocumentSnapshot<DocumentData> | undefined]> => {
  const [docs, last] = await getCookieLinkLaunchDetailRefs(db, startDoc, pageSize, scoutId);
  const details = docs
    ? docs.filter((doc) => doc && doc.data()).map((doc) => doc.data() as CookieLinkLaunchDetail)
    : undefined;
  return Promise.resolve(details ? [details, last] : [[], undefined]);
};

export const getAllCookieLinkLaunchDetails = async (
  db: Firestore,
  scoutId?: string | undefined
): Promise<CookieLinkLaunchDetail[]> => {
  const allDocs = [];
  let [docs, last] = await getCookieLinkLaunchDetails(db, undefined, 100, scoutId);
  if (docs) {
    allDocs.push(...docs);
  }
  while (last) {
    [docs, last] = await getCookieLinkLaunchDetails(db, last, 100, scoutId);
    if (docs) {
      allDocs.push(...docs);
    }
  }
  return Promise.resolve(allDocs as unknown as CookieLinkLaunchDetail[]);
};

export const deleteAllCookieLinkLaunchDetails = async (
  db: Firestore,
  scoutId?: string | undefined
) => {
  const allDocs = [];
  let [docs, last] = await getCookieLinkLaunchDetailRefs(db, undefined, 100, scoutId);
  if (docs) {
    allDocs.push(...docs);
  }
  while (last) {
    [docs, last] = await getCookieLinkLaunchDetailRefs(db, last, 100, scoutId);
    if (docs) {
      allDocs.push(...docs);
    }
  }
  allDocs.forEach(async (doc) => {
    await deleteDoc(doc.ref);
  });
};
