import useAuth from './auth';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './Logout.css';

function Logout() {
  const authContext = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    authContext.logout();
    navigate('/', { replace: true });
  });

  return <div className="Logout">Logging out...</div>;
}

export default Logout;
