import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyAFryOAJ_nwjakwgf_04QhjbyqT3i5KAKY',
  authDomain: 'cookie-sales-107b5.firebaseapp.com',
  databaseURL: 'https://cookie-sales-107b5-default-rtdb.firebaseio.com/',
  projectId: 'cookie-sales-107b5',
  storageBucket: 'cookie-sales-107b5.appspot.com',
  messagingSenderId: '569895567988',
  appId: '1:569895567988:web:a93f862202550f5c180721',
  measurementId: 'G-QNXZ3KN9NN'
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
