import {
  addDoc,
  collection,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where
} from 'firebase/firestore';
import { Scout } from '../models/scout';

export const getScouts = async (db: Firestore): Promise<Scout[] | undefined> => {
  const scoutsRef = collection(db, 'scouts');
  const q = query(scoutsRef, orderBy('name', 'asc'), limit(10));
  const querySnapshot = await getDocs(q);
  const docs =
    querySnapshot && querySnapshot.docs
      ? querySnapshot.docs.map((doc) => doc.data() as Scout)
      : undefined;
  return Promise.resolve(docs);
};

export const saveScout = async (db: Firestore, scout: Scout): Promise<boolean> => {
  const scoutsRef = collection(db, 'scouts');
  const q = query(scoutsRef, where('id', '==', scout.id));
  const querySnapshot = await getDocs(q);
  const doc = querySnapshot.docs && querySnapshot.docs.length ? querySnapshot.docs[0] : undefined;
  if (doc) {
    try {
      await setDoc(doc.ref, scout);
    } catch (error) {
      console.error('Unable to save existing scout', error);
      return Promise.resolve(false);
    }
  } else {
    try {
      await addDoc(scoutsRef, scout);
    } catch (error) {
      console.error('Unable to save new scout', error);
      return Promise.resolve(false);
    }
  }

  return Promise.resolve(true);
};

export const getNextScout = (scouts: Scout[], id: string | undefined): Scout | undefined => {
  if (!id) {
    return;
  }
  const scoutIndex = scouts.findIndex((scout) => scout.id === id);
  let nextActiveScout: Scout | undefined = undefined;
  let i = scoutIndex + 1;
  let passes = 0;
  while (!nextActiveScout && passes <= 1) {
    if (i >= scouts.length) {
      i = 0;
      passes++;
    }
    const nextScout = scouts[i];
    if (nextScout && nextScout.enabled && nextScout.id !== id) {
      nextActiveScout = nextScout;
      break;
    }
    i++;
  }
  return nextActiveScout ? nextActiveScout : scouts[scoutIndex];
};

export const buildScoutIdToNameMap = (scouts: Scout[]): Map<string, string> => {
  return buildScoutIdToAttrValueMap(scouts, 'name');
};

export const buildScoutIdToColorMap = (scouts: Scout[]): Map<string, string> => {
  return buildScoutIdToAttrValueMap(scouts, 'color');
};

const buildScoutIdToAttrValueMap = (scouts: Scout[], attr: string): Map<string, string> => {
  const scoutIdToNameMap = new Map<string, string>();
  if (scouts) {
    scouts
      .filter((scout) => scout && scout.id?.length)
      .forEach((scout) => {
        const id = scout.id;
        const value = (scout as any)[attr] as string;
        scoutIdToNameMap.set(id, value);
      });
  }
  return scoutIdToNameMap;
};
