import { isDev } from './environment';

class Logger {
  static log = (message?: string, ...optionalParams: any[]): void => {
    if (isDev()) console.log(message, optionalParams);
  };

  static error = (message?: string, ...optionalParams: any[]): void => {
    console.error(message, optionalParams);
  };
}

export default Logger;
