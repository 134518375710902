export interface ErrorMessageProps {
  message: string | undefined;
}

export function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div className="w-full text-center inline-flex align-items-center justify-content-center">
      <div className="text-red-600">{props.message?.length ? props.message : 'Error'}</div>
    </div>
  );
}
