import {
  browserName,
  deviceType,
  browserVersion,
  osName,
  osVersion,
  getUA
} from 'react-device-detect';
import { ClientPlatformInfo } from '../models/client-platform-info';

export const getClientPlatformInfo = (): ClientPlatformInfo => {
  return {
    os: osName,
    osVersion: osVersion,
    browser: browserName,
    browserVersion: browserVersion,
    deviceType: deviceType,
    ua: getUA
  } as ClientPlatformInfo;
};
