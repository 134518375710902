import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { UserCredential } from 'firebase/auth';

export const getUserFromStorage = (): UserCredential => {
  const userFromStorageString = localStorage.getItem('user');
  const userFromStorage =
    userFromStorageString && userFromStorageString.length
      ? JSON.parse(userFromStorageString)
      : undefined;
  return userFromStorage;
};

export const setUserInStorage = (user: UserCredential | undefined): void => {
  if (!user) {
    localStorage.removeItem('user');
    return;
  }

  const userInStorageString = JSON.stringify(user);
  localStorage.setItem('user', userInStorageString);
};

export interface AuthContextType {
  user: UserCredential | undefined;
  setUser: (user: UserCredential | undefined) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: { children: ReactNode }): JSX.Element {
  const [user, setUser] = useState<UserCredential>();

  useEffect(() => {
    const userFromStorage = getUserFromStorage();
    if (userFromStorage) {
      setUser(userFromStorage);
    }
  }, []);

  function logout() {
    setUserInStorage(undefined);
    setUser(undefined);
  }

  const value = {
    user: user,
    setUser: (user: UserCredential) => {
      setUserInStorage(user);
      setUser(user);
    },
    logout
  } as AuthContextType;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default function useAuth(): AuthContextType {
  return useContext(AuthContext);
}
