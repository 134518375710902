import { Scout } from '../models/scout';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import './ScoutSummary.css';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { useState } from 'react';
import { ScoutEditor } from './ScoutEditor';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { db } from '../firebase';
import { deleteAllCookieLinkLaunchDetails } from '../repositories/cookie-link-launch-detail-repository';

export interface ScoutSummaryProps {
  scouts: Scout[];
  details: CookieLinkLaunchDetail[];
}

export function ScoutSummary(props: ScoutSummaryProps) {
  const [showEditor, setShowEditor] = useState(false);
  const [scouts, setScouts] = useState<Scout[]>(props.scouts);
  const [details] = useState<CookieLinkLaunchDetail[]>(props.details);
  const [editingScout, setEditingScout] = useState(undefined as unknown as Scout);

  const cards = scouts.map((scout) => {
    const totalClicks = details ? details.length : undefined;
    const clicks = details
      ? details.filter((detail) => detail.scoutId === scout.id).length
      : undefined;
    const clicksPct = details && clicks != undefined ? (clicks / details.length) * 100 : undefined;
    const directClicks = details
      ? details.filter((detail) => detail.scoutId === scout.id && detail.direct).length
      : undefined;
    const directClickPct =
      clicks != undefined && directClicks != undefined ? (directClicks / clicks) * 100 : undefined;
    const header = (
      <div className="pt-3 pl-3 pr-3">
        <span className="font-bold text-xl">{scout.name}</span>
        <span
          className="pl-1 cursor-pointer"
          title="Configure Scout"
          onClick={() => {
            setEditingScout(scout);
            setShowEditor(true);
          }}
        >
          <i className="pi pi-cog"></i>
        </span>
        <span
          className="pl-1 cursor-pointer"
          title="Reset Clicks"
          onClick={() => {
            confirmDialog({
              message: `Continuing will clear all previous click records for ${scout.name}, are you sure you want to continue?`,
              header: 'Confirmation',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                console.log('reset clicks for scout ' + scout.id);
                deleteAllCookieLinkLaunchDetails(db, scout.id);
              }
            });
          }}
        >
          <i className="pi pi-replay"></i>
        </span>
      </div>
    );
    return (
      <Card className="scout-summary-card" header={header} key={scout.id}>
        <div className="text-left">
          <span className="font-semibold text-lg">
            {clicksPct != undefined ? Math.round(clicksPct) : '?'}%
          </span>
          <span className="text-lg pl-1">
            ({clicks != undefined ? clicks : '?'}/{totalClicks != undefined ? totalClicks : '?'})
          </span>
        </div>
        <div className="text-left">
          <span className="text-lg">
            <span className="font-medium">
              {directClickPct != undefined ? Math.round(directClickPct) : '?'}%
            </span>
            <span className="text-lg pl-1">direct</span>
          </span>
        </div>
      </Card>
    );
  });

  return (
    <div className="ScoutSummary">
      <div className="flex flex-wrap">{cards}</div>
      <Dialog
        header={editingScout?.name}
        visible={showEditor}
        onHide={() => setShowEditor(false)}
        breakpoints={{ '960px': '75vw' }}
        style={{ width: '50vw' }}
      >
        <ScoutEditor
          scout={editingScout}
          onSave={(updatedScout) => {
            setShowEditor(false);
            const updatedScouts = [] as Scout[];
            for (const scout of scouts) {
              if (!scout || scout.id !== updatedScout.id) {
                updatedScouts.push(scout);
              } else {
                updatedScouts.push(updatedScout);
              }
            }
            setScouts(updatedScouts);
          }}
        ></ScoutEditor>
      </Dialog>
      <ConfirmDialog />
    </div>
  );
}
