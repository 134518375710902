import { addDoc, collection, Firestore, getDocs, limit, orderBy, query } from 'firebase/firestore';
import CookieLinkLaunch from '../models/cookie-link-launch';

export const getLatestCookieLinkLaunch = async (
  db: Firestore
): Promise<CookieLinkLaunch | undefined> => {
  const cookieLinkLaunchesRef = collection(db, 'cookie-link-launches');
  const q = query(cookieLinkLaunchesRef, orderBy('clickedAt', 'desc'), limit(1));
  const querySnapshot = await getDocs(q);
  const doc = querySnapshot.docs && querySnapshot.docs.length ? querySnapshot.docs[0] : undefined;
  return Promise.resolve(doc?.data() as CookieLinkLaunch);
};

export const publishCookieLinkLaunch = async (
  db: Firestore,
  cookieLinkLaunch: CookieLinkLaunch
): Promise<string | undefined> => {
  const cookieLinkLaunchesRef = collection(db, 'cookie-link-launches');
  const docRef = await addDoc(cookieLinkLaunchesRef, cookieLinkLaunch);
  return Promise.resolve(docRef?.id);
};
