import { Scout } from '../models/scout';
import CookieLinkLaunchDetail from '../models/cookie-link-launch-details';
import { Column, DataTable } from 'primereact';
import './LinkLaunchDetailsTable.css';
import { Button } from 'primereact/button';

export interface LinkLaunchDetailsTableProps {
  scouts: Scout[];
  details: CookieLinkLaunchDetail[];
}

export function LinkLaunchDetailsTable(props: LinkLaunchDetailsTableProps) {
  const scouts = props.scouts;
  const details = props.details;

  const timestampTemplate = (detail: CookieLinkLaunchDetail) => {
    return <span>{detail.clickedAt ? detail.clickedAt.toDate().toLocaleString() : 'Unknown'}</span>;
  };

  const directTemplate = (detail: CookieLinkLaunchDetail) => {
    if (detail && detail.direct) {
      return <i className="pi pi-check"></i>;
    } else {
      return <span></span>;
    }
  };

  const deviceTypeTemplate = (detail: CookieLinkLaunchDetail) => {
    if (detail && detail.clientPlatformInfo && detail.clientPlatformInfo.deviceType === 'mobile') {
      return <i className="pi pi-mobile text-xl" title="Mobile"></i>;
    } else if (
      detail &&
      detail.clientPlatformInfo &&
      detail.clientPlatformInfo.deviceType === 'browser'
    ) {
      return <i className="pi pi-desktop text-xl" title="Desktop"></i>;
    } else {
      return <i className="pi pi-question-circle text-xl" title="Unknown"></i>;
    }
  };

  const scoutTemplate = (detail: CookieLinkLaunchDetail) => {
    const matchingScout =
      detail && detail.scoutId && scouts
        ? scouts.find((scout) => scout.id === detail.scoutId)
        : undefined;
    return <span> {matchingScout ? matchingScout.name : 'Unknown'}</span>;
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
  const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;

  return (
    <div className="LinkLaunchDetailsTable">
      <DataTable
        value={details}
        responsiveLayout="scroll"
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        rows={5}
        rowsPerPageOptions={[5, 10, 50, 100]}
        paginatorLeft={paginatorLeft}
        paginatorRight={paginatorRight}
      >
        <Column field="clickedAt" header="Time" body={timestampTemplate}></Column>
        <Column field="scoutId" header="Scout" body={scoutTemplate}></Column>
        <Column field="direct" header="Direct Click" body={directTemplate}></Column>
        <Column
          field="clientPlatformInfo.deviceType"
          header="Device"
          body={deviceTypeTemplate}
        ></Column>
        <Column field="clientInfo.country" header="Country"></Column>
        <Column field="clientInfo.region" header="State/Region"></Column>
        <Column field="clientInfo.city" header="City"></Column>
        <Column field="clientInfo.postal" header="Zip Code"></Column>
      </DataTable>
    </div>
  );
}
