import { ClientInfo } from '../models/client-info';
import Logger from '../logger';

const clientInfoApiUri = 'https://ipwho.is/';

export const getClientInfo = async (): Promise<ClientInfo> => {
  try {
    const response = await fetch(clientInfoApiUri, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });
    if (response && response.ok) {
      const json = await response.json();
      return Promise.resolve(json as ClientInfo);
    } else {
      Logger.error(
        `Unable to retrieve client info from API, response was not OK: ${response.statusText}`
      );
      return Promise.reject();
    }
  } catch (error) {
    Logger.error('Unable to retrieve client info from API', error);
    return Promise.reject();
  }
};
